.root {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.contentCardsTitle {
	padding-bottom: var(--space-sm);

	@media (--md) {
		padding-bottom: var(--space-lg);
	}
}

.contentCardsMobile {
	max-width: 100%;
	display: block;

	@media (--md) {
		display: none;
	}
}

.contentCardsDesktop {
	display: none;

	@media (--md) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 2px;
	}
}
