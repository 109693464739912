.root {
	border: unset;
	width: 100%;
	height: 50px;
	animation: skeleton-loading 1.5s infinite alternate;
}

@keyframes skeleton-loading {
	0% {
		background-color: #e5e7eb;
	}

	100% {
		background-color: white;
	}
}
