.root {
	width: fit-content;
	height: 50px;
	position: fixed;
	bottom: 1rem;
	left: 50%;
	padding-inline: 1rem;
	display: flex;
	gap: 0.5rem;
	transform: translateX(-50%) translateY(0);
	background-color: #121212;
	color: white;
	border-radius: 999px;
	z-index: 99999;
	overflow: hidden;
	box-sizing: 0 8px 30px rgb(0 0 0 / 12%);

	@media (hover: hover) {
		&:hover {
			transform: translateX(-50%) translateY(0);
		}
	}
}

.flex {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.separator {
	width: 1px;
	height: 100%;
	display: block;
	background-color: rgb(255 255 255 / 10%);

	&[dir=":horizontal"] {
		width: 100%;
		height: 1px;
		margin-block: 0.5rem;
	}
}

.inner {
	padding: 0.25rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: 500;
}

.iconButton {
	width: 2rem;
	height: 2rem;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	flex-grow: 1;
	border: none;
	background: none;
	color: white;
	transition: all 0.3s ease;

	& > svg {
		width: 60%;
		height: 60%;
	}

	@media (hover: hover) {
		&:hover {
			background-color: rgb(255 255 255 / 10%);
		}
	}

	&.spin {
		& > svg {
			animation: spin 1s linear infinite;
		}
	}
}

@keyframes spin {
	from {
		rotate: 0deg;
	}

	to {
		rotate: 360deg;
	}
}

.modalViewport {
	width: min(calc(100vw - 20px), 700px);
	position: fixed;
	top: unset;
	bottom: 5rem;
	border: none;
	background-color: #121212;
	border-radius: 1rem;
	color: white;
	padding: 1rem;

	& label {
		display: block;
	}
}

.formStyles {
	& select,
	input {
		height: fit-content;
		padding: 0.5rem;
		background-color: transparent;
		border: 1px solid rgb(255 255 255 / 10%);
		border-radius: 0.5rem;
		color: white;
	}

	& label {
		display: grid;
		grid-template-areas: "label input";
		align-items: center;
		gap: 1rem;

		& > [role="group"] {
			color: rgb(255 255 255 / 60%);

			& > span {
				font-size: 1rem;
				font-weight: 600;
				color: white;
			}

			grid-area: label;
		}

		& > select,
		& > input {
			grid-area: input;
		}
	}
}

.button {
	all: unset;
	background-color: #0070f3;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: 600;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			scale: 1.05;
		}
	}

	transition: all 0.3s ease;
}
