/* stylelint-disable keyframes-name-pattern */

/*
   * @source https://dialog5.vercel.app/
   * @source-author Sanna Jammeh
   */

:where(.viewport) {
	--modal-width: min(538px, 100vw);
	--top-offset: var(--modal-offset, var(--space-sm));

	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: calc(100dvh - var(--header-min-height) - var(--snackbar-visibility, 0px));
	position: fixed;
	top: calc(var(--header-min-height) + var(--snackbar-visibility, 0px));
	left: 0;
	right: 0;
	margin: 0;
	padding: 0;
	display: block;
	border: none;
	z-index: 1000;
	will-change: transform, visibility, top, max-height;

	/* transition: 0.3s ease;
  transition-property: visibility, clip-path; */

	border-radius: 0;

	@media (--md) {
		transition:
			visibility 0.3s ease,
			top 0.3s ease,
			max-height 0.3s ease;
		width: min(var(--modal-width, 30vmax), calc(100vw - var(--page-inset)));
		max-width: 538px;
	}

	@media (--md) {
		--offset: calc(
			var(--header-min-height) + var(--snackbar-visibility, 0px) + var(--modal-offset)
		);

		max-height: min(calc(100vh - var(--offset) - var(--space-sm)), 840px);
		top: var(--offset);
		border-radius: 8px;

		&.left {
			left: 20px;
			right: unset;
		}

		&.right {
			left: unset;
			right: 20px;
		}
	}

	&::backdrop {
		opacity: 0;
	}

	&[open] {
		visibility: visible;
		opacity: 1;

		@media (--md) {
			animation: slideUpFade 0.3s ease forwards;
		}
	}

	&:not([open]) {
		pointer-events: none;
		visibility: hidden;
		opacity: 0;

		@media (--md) {
			animation: slideDownFade 0.3s ease forwards;
		}
	}

	&.maxMdFull {
		@media (--max-md) {
			width: 100%;
			height: 100%;
			max-height: 100%;
			top: 0;
		}
	}
}

:where(.content) {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: var(--color-white);

	@media (--md) {
		border-radius: var(--space-md);
		overflow: hidden;
	}
}

.close {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.header {
	padding: var(--space-lg) var(--space-sm) var(--space-sm) var(--space-sm);
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@media (--md) {
		padding: var(--space-lg) var(--space-lg) var(--space-sm) var(--space-lg);
	}
}

.body {
	flex-grow: 1;

	& > * {
		height: 100%;
		position: relative;
	}
}

.footer {
	margin-top: auto;
	padding: 15px 20px;
	box-shadow: 0 -2px 4px rgb(0 0 0 / 5%);
	z-index: 10;
}

@keyframes slideUpFade {
	from {
		opacity: 0;
		transform: translateY(-20px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideDownFade {
	from {
		opacity: 1;
		transform: translateY(0);
	}

	to {
		opacity: 0;
		transform: translateY(-20px);
	}
}
