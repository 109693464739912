.root {
	@media (--md) {
		& .header {
			padding-bottom: 90px;
		}
	}

	& .listingGrid {
		display: grid;
		gap: 30px 2px;
		grid-template-columns: repeat(auto-fit, minmax(min(250px, 50vw), 1fr));

		@media (--md) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	& .filterWrapper {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	& .filter {
		padding-block: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 20px;
		white-space: nowrap;

		@media (--md) {
			padding-bottom: 0;
		}
	}

	& .showAll {
		margin-block: 60px;
		display: flex;
		justify-content: center;
	}
}
