:where(.root) {
	padding-block: 60px;

	@media (--md) {
		padding-block: 90px;
	}

	& .isTop {
		margin-top: var(--space-xl);
	}
}
