.root {
	border-radius: 50%;
	background-color: var(--amount-dot-background);
	color: var(--amount-dot-color);
	width: var(--dot-size);
	height: var(--dot-size);
	border: none;
	text-decoration: none;
	display: inline-flex;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
	font-weight: 500;

	&.blue {
		--amount-dot-background: var(--primary-dark-blue);
		--amount-dot-color: var(--primary-white);
	}

	&.beige {
		--amount-dot-background: var(--secondary-beige);
		--amount-dot-color: var(--primary-dark-blue);
	}

	&.yellow {
		--amount-dot-background: var(--accent-yellow);
		--amount-dot-color: var(--primary-dark-blue);
	}

	&.white {
		--amount-dot-background: var(--primary-white);
		--amount-dot-color: var(--primary-dark-blue);
	}

	&.inactive {
		--amount-dot-background: var(--primary-dark-blue-005);
		--amount-dot-color: var(--primary-dark-blue-02);
	}

	&.bold {
		font-weight: 500;
	}

	&.notification {
		--dot-size: 17px;

		/* Notification - custom one off styles from Figma */
		font-size: 7px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&.xSmall {
		@extend %typo-utility-6;

		--dot-size: 24px;
	}

	&.small {
		@extend %typo-utility-6;

		--dot-size: 24px;

		@media (--md) {
			@extend %typo-utility-5;

			--dot-size: 32px;
		}
	}

	&.medium {
		@extend %typo-utility-4;

		--dot-size: 40px;
	}

	&.large {
		@extend %typo-utility-3;

		--dot-size: 48px;
	}
}

.dotContainer {
	width: fit-content;
	position: relative;

	& .root {
		position: absolute;
		top: 0;
		right: 0;
		transform: translateX(50%) translateY(-25%);
	}
}
