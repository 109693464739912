.root {
	--bottom-space: 14px;
	--indicator-size: 3px;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: var(--space-sm);

	@media (--md) {
		gap: var(--space-lg);
	}

	&.large {
		--bottom-space: 12px;
	}

	&.small {
		--bottom-space: 7px;
		--indicator-size: 2px;
	}

	& .tab {
		position: relative;
		padding: 0;
		color: var(--primary-dark-blue-08);
		border: none;
		background-color: transparent;
		cursor: pointer;
		line-height: 1;
		padding-block: calc(var(--bottom-space) / 2);

		&::after {
			width: 100%;
			height: var(--indicator-size);
			position: absolute;
			bottom: 0;
			left: 0;
			border-radius: var(--border-rounded);
			opacity: 0;
			content: "";
			transition: opacity 0.2s ease;
		}

		@media (hover: hover) {
			&:hover {
				&::after {
					opacity: 1;
					background-color: var(--primary-dark-blue-01);
				}
			}
		}

		&.active {
			&::after {
				opacity: 1;
				background-color: var(--accent-yellow);
			}
		}
	}
}

.tabName {
	@extend %typo-utility-4;

	@media (--md) {
		@extend %typo-utility-3;
	}
}

.large {
	.tabName {
		@extend %typo-utility-3;

		@media (--md) {
			@extend %typo-utility-1;
		}
	}
}
