.header {
	margin-top: 56px;

	@media (--md) {
		margin-top: 90px;
	}
}

.tabBar {
	--margin-top: 30px;

	margin-top: var(--margin-top);
	justify-content: flex-start;

	@media (--md) {
		--margin-top: 90px;
	}
}

.orders {
	margin-top: 12px;

	@media (--md) {
		margin-top: 22px;
	}
}

.tableWrapper {
	--padding: 30px 0;

	overflow-x: auto;
	width: 100%;

	& .table {
		width: 100%;
		border-collapse: collapse;
		min-width: 600px;

		& .tableHeader {
			text-align: left;
			padding: var(--padding);
		}

		& .desktopOrder td {
			padding: var(--padding);
			border-bottom: 1px solid var(--primary-dark-blue-01);
		}
	}
}

.mobileOrder {
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--primary-dark-blue-01);
	gap: 20px;

	& .mobileRow {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
	}
}

& .tracking {
	margin-top: 15px;

	& .trackNo {
		text-decoration: underline;
	}
}
