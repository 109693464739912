.root {
	--height: 4px;
	--value-bg: var(--accent-yellow);
	--bg: var(--primary-dark-blue-02);
	--radius: calc(var(--height) * 2);

	width: 100%;
	height: var(--height);
	position: relative;
	border-radius: 99;
	background-color: var(--bg);
	overflow: hidden;
}

.progress {
	height: 100%;
	position: absolute;
	border-radius: var(--radius);
	background-color: var(--value-bg);
	top: 0;
	left: 0;
}
