.root {
	--row-gap: 53px;

	display: flex;
	flex-direction: column;
	gap: var(--row-gap);

	@media (--md) {
		--row-gap: 60px;
	}

	& .content {
		width: 100%;
		max-width: 700px;
		margin: 0 auto;

		& .back {
			margin: 0 auto;
			color: inherit;
			text-decoration: none;
		}
	}

	& .header {
		--padding: 8px 0 16px 15px;

		padding: var(--padding);

		@media (--md) {
			--padding: 8px 0 16px 30px;
		}
	}

	& .form {
		margin-top: 0;

		@media (--md) {
			margin-top: 90px;
		}
	}

	& .section {
		--gap: 20px;

		padding: var(--space-sm);
		display: flex;
		flex-direction: column;
		gap: var(--gap);

		@media (--md) {
			--gap: 30px;
		}
	}

	& .button {
		width: 100%;
		background: var(--secondary-blue);
	}

	& .center {
		text-align: center;
	}
}
