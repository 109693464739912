.root.root {
	--flag-btn-padding: 0px 16px;
	--flag-btn-height: 56px;

	@media (--md) {
		--flag-btn-padding: 14px 24px;
		--flag-btn-height: 52px;
	}

	width: 100%;
	border-radius: var(--border-rounded);
	height: var(--flag-btn-height);
	margin-inline: 0;
	padding: var(--flag-btn-padding);
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid var(--primary-dark-blue-01);
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			background-color: var(--primary-dark-blue-005);
			border-color: var(--border-active);
		}
	}

	&:not(.bypassColor) {
		background-color: var(--primary-white);
		color: var(--primary-dark-blue-06);

		@media (hover: hover) {
			&:hover {
				background-color: var(--primary-dark-blue-005);
			}
		}
	}

	& .main {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: var(--space-xs);
	}

	& .flagImage {
		--image-size: 24px;

		order: 1;
		width: var(--image-size);
		height: var(--image-size);
		position: relative;
		display: flex;
		align-items: center;

		& img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	& .text {
		@extend %typo-utility-3;

		order: 2;
		width: 100%;
		margin: auto 0;
		flex: 1;
		text-align: left;
		color: var(--primary-dark-blue-06);

		@media (--md) {
			@extend %typo-utility-4;
		}
	}
}

.selected.selected.selected {
	background-color: var(--primary-dark-blue-005);
	border-color: var(--border-active);
}
