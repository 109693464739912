:where(.root) {
	--button-bg: var(--primary-white);
	--button-color: var(--primary-dark-blue);
	--button-height: 51px;

	@extend %typo-utility-3;

	width: fit-content;
	height: var(--button-height);
	position: relative;
	padding-inline: var(--button-padding-inline);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	font-weight: 500;
	border: none;
	background-color: var(--button-bg);
	color: var(--button-color);
	text-decoration: none;
	transition:
		transform 0.3s ease,
		background-color 0.3s ease,
		color 0.3s ease;

	/* Active states */
	@media (hover: hover) {
		&:hover {
			background-color: var(--button-bg-hover, var(--button-bg));
			cursor: pointer;
		}
	}

	&.small {
		@extend %typo-utility-4;

		--button-height: 39px;
		--button-padding-inline: 15px;
	}

	&.medium {
		--button-height: 51px;
		--button-padding-inline: 25px;
	}

	&.large {
		@extend %typo-utility-2;

		--button-height: 56px;
		--button-padding-inline: 25px;
	}

	&.xLarge {
		@extend %typo-utility-1;

		--button-height: 64px;
		--button-padding-inline: 40px;
	}

	@media (--md) {
		&.md-large {
			@extend %typo-utility-2;

			--button-height: 56px;
			--button-padding-inline: 25px;
		}

		&.md-xLarge {
			@extend %typo-utility-1;

			--button-height: 64px;
			--button-padding-inline: 40px;
		}
	}

	&.secondaryBlue {
		--button-bg: var(--secondary-blue);
		--button-color: var(--primary-white);

		@media (hover: hover) {
			&:hover {
				--button-bg: color-mix(in srgb, var(--secondary-blue) 80%, #000 20%);
			}
		}

		&:active,
		&:focus {
			--button-bg: #000a18;
		}
	}

	&.darkblue {
		--button-bg: var(--primary-dark-blue);
		--button-color: var(--primary-white);

		@media (hover: hover) {
			&:hover {
				--button-bg: color-mix(in srgb, var(--primary-dark-blue) 80%, #000 20%);
			}
		}

		&:active,
		&:focus {
			--button-bg: #000a18;
		}
	}

	&.greige {
		--button-bg: var(--primary-greige);

		@media (hover: hover) {
			&:hover {
				--button-bg: color-mix(in srgb, var(--primary-greige) 98%, #000 2%);
			}
		}

		&:active,
		&:focus {
			--button-bg: color-mix(in srgb, var(--primary-greige) 95%, #000 5%);
		}
	}

	&.white {
		--button-bg: var(--primary-white);

		border: 1px solid var(--primary-dark-blue-01);

		@media (hover: hover) {
			&:hover {
				--button-bg: color-mix(in srgb, var(--primary-white) 98%, #000 2%);
			}
		}

		&:active,
		&:focus {
			--button-bg: color-mix(in srgb, var(--primary-white) 95%, #000 5%);
		}
	}

	&.yellow {
		--button-bg: var(--accent-yellow);

		@media (hover: hover) {
			&:hover {
				--button-bg: color-mix(in srgb, var(--accent-yellow) 98%, #000 2%);
			}
		}

		&:active,
		&:focus {
			--button-bg: color-mix(in srgb, var(--accent-yellow) 95%, #000 5%);
		}
	}

	&.inactive,
	&:disabled {
		--button-bg: var(--primary-dark-blue-005);
		--button-color: var(--primary-dark-blue-02);

		@media (hover: hover) {
			&:hover {
				--button-bg: var(--primary-dark-blue-005);
				--button-color: var(--primary-dark-blue-02);

				cursor: not-allowed;
			}
		}
	}

	&.roundedFull {
		border-radius: 999px;
	}

	&.roundedMd {
		border-radius: fluidSize(8px, 10px);
	}

	&.roundedLg {
		border-radius: fluidSize(10px, 10px, 20px);
	}

	/* Hide content on loading, show spinner */
	& .spinner {
		display: none;
	}

	&.isLoading {
		& > *:not(.spinner) {
			visibility: hidden;
		}

		& .spinner {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			transform: translate(-50%, -50%);
			z-index: 10;
			font-size: 1.5em;
		}
	}

	& .innerButton {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: inherit;
		gap: inherit;
	}
}

:where(.unstyled) {
	margin: 0;
	padding: 0;
	appearance: none;
	background-color: transparent;
	border: none;
	color: inherit;
	font: inherit;
	cursor: pointer;
}
