.root {
	display: flex;
}

.column {
	flex-direction: column;
}

.row {
	flex-direction: row;
}

.justifyStart {
	justify-content: flex-start;
}

.justifyCenter {
	justify-content: center;
}

.justifyEnd {
	justify-content: flex-end;
}

.justifyBetween {
	justify-content: space-between;
}

.justifyEvenly {
	justify-content: space-evenly;
}

.alignStart {
	align-items: flex-start;
}

.alignCenter {
	align-items: center;
}

.alignEnd {
	align-items: flex-end;
}

.alignStretch {
	align-items: stretch;
}

.gap-xs {
	gap: var(--space-xs);
}

.gap-sm {
	gap: var(--space-sm);
}

.gap-md {
	gap: var(--space-md);
}

.gap-lg {
	gap: var(--space-lg);
}

.gap-xl {
	gap: var(--space-xl);
}

.gap-xxl {
	gap: var(--space-2xl);
}
