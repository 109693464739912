.root {
	width: fit-content;
	height: fit-content;
	padding: var(--tag-padding);
	border-radius: var(--tag-border-radius);
	background-color: var(--tag-background);
	color: var(--tag-color);
	border: none;
	text-decoration: none;
	text-transform: capitalize;

	&.blue {
		--tag-background: var(--secondary-blue);
		--tag-color: var(--primary-white);
	}

	&.white {
		--tag-background: var(--primary-white);
		--tag-color: var(--primary-dark-blue);
	}

	&.red {
		--tag-background: var(--accent-red);
		--tag-color: var(--primary-white);
	}

	&.transparent {
		--tag-background: var(--primary-dark-blue-04);
		--tag-color: var(--primary-white);
	}

	&.beige {
		--tag-background: var(--secondary-beige);
		--tag-color: var(--primary-dark-blue);
	}

	&.mini {
		--tag-border-radius: 4px;
		--tag-padding: 6px;
	}

	&.xSmall {
		--tag-border-radius: 4px;
		--tag-padding: 8px;
	}

	&.small {
		--tag-border-radius: 6px;
		--tag-padding: 6px;

		@media (--md) {
			--tag-padding: 10px;
		}
	}

	&.large {
		--tag-border-radius: 8px;
		--tag-padding: 15px;
	}

	&.root {
		line-height: 0.737;
	}

	&.medium {
		font-weight: 500;
	}

	&.normal {
		font-weight: 400;
	}
}
