.root {
	--fade-color: var(--color-background);

	position: relative;
	overflow: hidden;

	&.fadeY {
		&[data-state="closed"]::after {
			position: absolute;
			inset: 0;
			content: "";
			background: linear-gradient(to bottom, transparent, var(--fade-color));
			pointer-events: none;
		}
	}

	&[data-state="closed"] {
		max-height: var(--collapsed-height);
	}
}

.viewButton {
	margin: 21 auto 0;
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
}

.readMore {
	--clamp: 4;

	display: -webkit-box;
	-webkit-line-clamp: var(--clamp);
	line-clamp: var(--clamp);
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	font: inherit;

	@media (--md) {
		--clamp: 3;
	}

	&.open {
		-webkit-line-clamp: initial;
		line-clamp: initial;
		display: initial;
		overflow: initial;
	}
}

.readMoreButton {
	background: none;
	border: none;
	color: inherit;
	font: inherit;
	text-decoration: underline;
	cursor: pointer;
	padding: 0;
	display: inline;
}
