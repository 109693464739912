.root {
	width: 100%;
	min-width: 85%;
	height: 100%;
	padding: 0;
	flex-grow: 1;
	color: black;
}

.contentCardImage {
	--media-aspect: 1 / 1;

	aspect-ratio: 1 / 1;
	position: relative;

	& img {
		object-fit: cover;
	}
}

.contentCardTitle {
	padding: 20px 0 0 15px;
}
