.root {
	--media-aspect: 4 / 5;
	--card-radius: 4px;

	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	color: inherit;
	text-decoration: none;

	& .cardBody {
		background-color: var(--primary-grey);
		width: 100%;
		position: relative;
		border-radius: var(--card-radius);

		& > img {
			width: 100%;
			height: auto;
			aspect-ratio: 4 / 5;
			border-radius: var(--card-radius);
			object-fit: cover;
		}
	}

	& .overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		inset: 0;
		z-index: 20;
	}

	& .cardTag {
		margin-top: auto;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& .cardFooter {
		width: 100%;
		padding: 15px 10px 0;
		display: grid;
		grid-template-areas: "footerInfo favourite";
		grid-template-columns: 1fr auto;

		@media (--md) {
			padding: 20px 15px 12px;
		}

		& .footerInfo {
			grid-area: footerInfo;
			display: flex;
			flex-direction: column;
			gap: var(--space-2xs);

			& dd {
				line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
		}
	}

	& .skeletonImage {
		width: 100%;
		height: auto;
		aspect-ratio: var(--media-aspect);
	}
}
