.root {
	position: relative;
}

.aspectLandscape {
	--media-aspect: 75 / 44;

	@media (--md) {
		--media-aspect: 17 / 8;
	}
}

.aspectPortrait {
	--media-aspect: 4 / 5;
}

.aspectDefault {
	--media-aspect: var(--default-aspect);
}

.asset {
	max-width: 100%;
	aspect-ratio: var(--media-aspect, var(--default-aspect));
	position: relative;

	& img,
	& video {
		object-fit: cover;
	}

	& video {
		width: 100%;
		height: 100%;
		position: absolute;
		inset: 0;
	}

	& img {
		object-position: var(--object-position, var(--internal-object-position));
	}
}
