:where(.input) {
	--border-color: var(--primary-dark-blue-01);
	--border-color-active: var(--primary-dark-blue-04);
	--input-border: 1px solid var(--border-color);
	--input-border-active: 1px solid var(--border-color-active);
	--padding: 14px 24px;

	@media (--md) {
		--padding: 16px 24px;
	}

	width: 100%;
	height: 54px;
	display: flex;
	align-items: center;
	transition: var(--transition-colors);
	border-radius: var(--border-rounded);
	border: var(--input-border);
	font-family: inherit;
	background-color: var(--primary-white);

	&:focus-within {
		background-color: var(--primary-dark-blue-005);
		border: var(--input-border-active);
	}

	& input {
		width: 100%;
		height: 100%;
		padding: var(--padding);
		border: none;
		outline: none;
		background-color: transparent;
		font-size: 13px;

		@media (--md) {
			padding: var(--padding);
		}

		&::placeholder {
			color: var(--primary-dark-blue-06);
		}

		&:disabled {
			cursor: not-allowed;
		}

		&[type="search"] {
			appearance: none;

			&::-webkit-search-cancel-button {
				display: none;
			}
		}
	}

	& button {
		all: unset;
	}
}

.label {
	--input-size: min(--body-2-size, 16px);

	width: 100%;
	height: fit-content;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: var(--space-2xs);

	&.error {
		& .labelText {
			color: var(--accent-red);
		}

		& .input {
			--border-color: var(--accent-red);
		}

		& .errorText {
			position: block;
			color: var(--accent-red);
		}
	}
}

.textField {
	display: none;
}

.searchIcon {
	margin: var(--space-sm) 25px var(--space-sm) 0;
	cursor: pointer;
}
