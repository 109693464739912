.root {
	width: 100%;
	padding: var(--space-sm);
	background-color: var(--primary-white);

	& .suggestions {
		margin: var(--space-md) var(--space-sm) var(--space-2xs) var(--space-sm);
		display: flex;
		gap: var(--space-lg);

		& .suggestionItems {
			display: flex;
			flex-direction: column;
			gap: var(--space-sm);

			@media (--md) {
				flex-direction: row;
				align-items: center;
			}
		}

		@media (--md) {
			align-items: center;
		}
	}
}

.resultWrapper {
	padding: var(--space-xl) 0 var(--space-sm) 0;

	@media (--md) {
		padding: 90px 161px;
	}

	display: flex;
	align-items: center;
	justify-content: center;

	& .result {
		width: 100%;
		padding: var(--space-2xs) var(--space-sm) 0 var(--space-sm);
		display: grid;
		grid-template-areas:
			"resultHeader"
			"resultQuery";
		row-gap: var(--space-sm);

		@media (--md) {
			row-gap: var(--space-lg);
			padding: 0;
		}

		& .resultHeader {
			grid-area: resultHeader;
		}

		& .resultQuery {
			display: flex;
			justify-content: space-between;
			gap: 0;
			grid-area: resultQuery;

			@media (--md) {
				justify-content: flex-start;
				gap: 16px;
			}

			& .total {
				margin: auto 0;
			}
		}
	}
}

.viewport {
	width: 100%;
	height: fit-content;

	@media (--md) {
		min-width: 722px;
	}
}

.content {
	height: fit-content;
	max-height: fit-content;
	padding: 0;
}
