.ScrollAreaRoot {
	--scrollbar-size: 2px;

	width: 100%;
	height: auto;
	overflow: hidden;
}

:where(.ScrollAreaViewport) {
	width: 100%;
	height: 100%;
	border-radius: inherit;
	padding-bottom: 10px;
}

:where(.ScrollAreaScrollbar) {
	width: 55%;
	display: flex;
	user-select: none;

	/* disable browser handling of all panning and zooming gestures on touch devices */
	touch-action: none;
	background: var(--primary-grey);
	transition: var(--transition-standard);
	transition-property: background, opacity, visibility;
	border-radius: var(--scrollbar-size);

	@media (hover: hover) {
		&:hover {
			background: var(--primary-grey);
		}
	}

	&[data-state="hidden"] {
		visibility: hidden;
		opacity: 0;
	}

	&[data-orientation="vertical"] {
		width: var(--scrollbar-size);
		margin-block: var(--space-sm);
		margin-inline-end: var(--space-2xs);

		@media (--md) {
			margin-inline-end: var(--space-sm);
		}
	}

	&[data-orientation="horizontal"] {
		height: var(--scrollbar-size);
		position: static !important;
		margin-block: var(--space-sm);
		margin-inline: auto;
		flex-direction: column;
	}
}

.ScrollAreaThumb {
	flex: 1;
	background: var(--primary-dark-blue);
	border-radius: var(--scrollbar-size);
	position: relative;
}

.ScrollAreaThumb::before {
	width: 100%;
	min-width: 44px;
	height: 100%;
	min-height: 44px;
	position: absolute;
	top: 50%;
	left: 50%;
	content: "";
	transform: translate(-50%, -50%);
}

.ScrollAreaCorner {
	background: var(--primary-grey);
}
