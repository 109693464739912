.root {
	width: 100%;
	height: auto;
	aspect-ratio: 1 / 1;
	position: relative;
	color: white;
	flex-grow: 0;
	cursor: pointer;

	& img {
		object-fit: cover;
	}

	& .overlay {
		position: absolute;
		padding: 26px 25px 30px;
		display: grid;
		inset: 0;
		z-index: var(--z-above);
		grid-template-rows: repeat(3, 1fr);
		justify-items: center;

		& .header {
			align-self: start;
		}

		& .title {
			align-self: center;
		}

		& .button {
			align-self: end;
		}

		@media (--md) {
			padding-top: var(--space-xl);
			padding-bottom: var(--space-xl);
		}
	}

	@media (--md) {
		max-width: 50%;
	}
}
