.root {
	--flex-gap: 5px;

	display: flex;
	flex-direction: column;
	gap: var(--flex-gap);

	@media (--md) {
		--flex-gap: 2px;

		flex-direction: row;
	}
}
