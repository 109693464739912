.root {
	padding-top: var(--space-2xs);

	& .sideBySide {
		--gap: 15px;

		gap: var(--gap);
	}

	p {
		@media (--md) {
			width: fit-content;
			margin-left: auto;
		}
	}
}
