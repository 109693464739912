.intro {
	--spacing-y: 30px;

	padding-block: 0;
	padding-block: var(--spacing-y);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-y);

	@media (--md) {
		--spacing-y: 90px;
	}

	& .text {
		& .title {
			min-width: fit-content;
		}

		display: grid;
		grid-template-columns: 1fr;
		gap: var(--space-sm);

		@media (--md) {
			grid-template-columns: 1fr 1fr;
		}
	}

	& .subCategories {
		display: grid;
		grid-template-rows: auto auto;
		grid-auto-flow: column;
		grid-auto-columns: max-content;
		gap: 10px;

		@media (--md) {
			display: flex;
			flex-wrap: wrap;
		}

		& .skeleton {
			border-radius: 999;
		}
	}
}

.description {
	@extend %typo-body-1;
}
