.root {
	width: 100%;
	height: 100%;
	position: relative;

	&::after {
		position: absolute;
		inset: 0;
		content: "";
		background: linear-gradient(to bottom, rgb(0 0 0 / 40%) 0%, transparent 60%);
		pointer-events: none;
	}

	& .image_container {
		--media-aspect: 25 / 32;

		aspect-ratio: var(--media-aspect);
		position: relative;

		@media (--md) {
			--media-aspect: 64 / 35;
		}
	}

	&.isTop {
		margin-top: calc(var(--header-min-height) * -1);
	}

	& .title {
		hyphens: auto;
		margin-bottom: 10px;

		@media (--md) {
			max-width: 814px;
			margin-bottom: 30px;
		}
	}

	& .subtitle {
		margin-bottom: 10px;
	}

	& .content {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		color: var(--primary-white);
		z-index: var(--z-above);
	}

	& .contentInner {
		width: 100%;
		height: 100%;
		max-height: calc(100vh);
		position: sticky;
		top: 0;
		padding: 40px 0;
		display: flex;
		align-items: end;

		& .contentContainer {
			height: fit-content;
			margin-top: auto;
		}

		@media (--md) {
			padding: 100px 0;
		}
	}

	& .actions {
		display: flex;
		gap: 8px;

		@media (--md) {
			gap: 24px;
		}
	}
}
