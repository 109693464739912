.root {
	--item-gap: fluidSize(20px, 30px);

	display: flex;
	flex-direction: column;
	gap: var(--blok-margin);

	& .side {
		--content-width: fluidSize(543px, 543px);

		width: 100%;
		display: flex;
		flex-direction: column;
		gap: var(--item-gap);
	}

	@media (--md) {
		display: grid;
		grid-template-areas: "left right";
		grid-template-columns: 1fr 1fr;
		gap: var(--page-inset);

		& .left {
			--text-align: left;
			--content-margin: 0;
			--quote-width: 90%;

			grid-area: left;
			margin: auto;
		}

		& .right {
			grid-area: right;
			margin: auto;
		}
	}
}
