.root {
	padding: 6px;
	border-radius: 999px;
	display: flex;
	align-items: center;
	border: 1px solid var(--primary-dark-blue-01);
	width: fit-content;
	max-width: 100px;

	@media (--md) {
		padding: 8px;
		max-width: 120px;
	}
}

.output {
	@extend %typo-utility-2;

	user-select: none;
	width: 40px;
	text-align: center;

	& span {
		vertical-align: middle;
		line-height: 0;
	}

	@media (--md) {
		@extend %typo-utility-1;

		width: 56px;
		min-width: 56px;
	}
}

.icon {
	--size: 24px;
	width: var(--size);
	height: var(--size);
}
